<template lang="pug">
    b-container(fluid)
        b-row
            b-col.sidebar(md='4' lg='3' xl='2')
                b-form-group.mt-2(label='Start Date')
                    b-form-datepicker(:value='planDate' @input='updatePlanDate($event)')

                .d-flex.mt-2.mb-2
                    b-form-group.mb-0(label='Savings Target')
                        b-input-group(prepend='$'): b-form-input(
                                :value='savingsTarget'
                                @input='updateSavingsTarget(parseInt($event))'
                                type='number'
                                required
                            )

                    b-dropdown.ml-2(block style='align-self: flex-end')
                        template(#button-content)
                            i.fas.fa-cog
                        b-dropdown-item(@click='exportData') Export
                        b-dropdown-item(@click='importData') Import

                hr

                b-dropdown.mt-2(block)
                    template(#button-content)
                        i.fas.fa-add
                        |  Add
                    b-dropdown-item(@click='editIncome(null)') Income
                    b-dropdown-item(@click='editExpense(null)') Expense
                    b-dropdown-item(@click='editDebt(null)') Debt

                Money(v-for='money in incomes' :key='money.id' :money='money' @click.native='editIncome(money.id)')
                Money(v-for='money in expenses' :key='money.id' :money='money' @click.native='editExpense(money.id)')
                Money(v-for='money in debts' :key='money.id' :money='money' @click.native='editDebt(money.id)')

            b-col(md='8' lg='9' xl='10')
                debt-chart(:plan='plan')

                b-row.mt-4
                    b-col(xl='4' lg='6' v-for='month in plan.months' :key='month.date')
                        b-card.mb-3(
                            :header='monthLabel(month)'
                            no-body
                        )
                            b-list-group(flush)
                                b-list-group-item.debt-item(v-for='debt in sortedDebts(month.debts)' :key='debt.id' v-if='debt.payment')
                                    strong {{ debt.name }}:&nbsp;

                                    template(v-if='debt.payment === debt.minimum') Pay minimum of {{ debt.payment | money }}
                                    template(v-else) Pay {{ debt.payment | money }}
                                    span.debt-item__remaining.pl-1 ({{ debt.balance | money }} left)

                                    h4.text-success.mt-2(v-if='debt.balance === 0') 🎉 {{ debt.name }} is paid off!
                                b-list-group-item(v-if='month.savings > 0')
                                    div: strong.text-success Save {{ month.savings | money }}
                                    div Total Savings: {{ month.totalSavings | money }}
</template>

<script>
import _ from 'lodash'
import { DateTime } from 'luxon'
import {
    mapActions, mapGetters, mapMutations, mapState,
} from 'vuex'

import DebtChart from '@/components/DebtChart'
import Money from '@/components/Money'
import DebtForm from '@/forms/DebtForm'
import ExpenseForm from '@/forms/ExpenseForm'
import IncomeForm from '@/forms/IncomeForm'

export default {
    name: 'MainPage',

    components: { DebtChart, Money },

    computed: {
        ...mapGetters(['incomes', 'expenses', 'debts', 'plan']),
        ...mapState({
            monies: (state) => state.core.monies,
            planDate: (state) => state.core.planDate,
            savingsTarget: (state) => state.core.savingsTarget,
        }),
    },

    methods: {
        edit(form, id) {
            this.$present(form, {
                values: id ? this.monies[id] : {},
            })
        },

        editDebt(id) {
            this.edit(DebtForm, id)
        },

        editExpense(id) {
            this.edit(ExpenseForm, id)
        },

        editIncome(id) {
            this.edit(IncomeForm, id)
        },

        monthLabel(month) {
            return DateTime.fromISO(month.date).toFormat('MMMM yyyy')
        },

        sortedDebts(debts) {
            return _.sortBy(debts, 'name')
        },

        ...mapActions(['exportData', 'importData']),
        ...mapMutations(['updatePlanDate', 'updateSavingsTarget']),
    },
}
</script>

<style lang="scss" scoped>
.sidebar {
    background: #f7f7f9;
    min-height: 100vh;
}

.debt-item {
    &__remaining {
        display: none;
    }

    &:hover {
        background: #f9f9f9;

        .debt-item__remaining {
            display: inline-block;
        }
    }
}
</style>
