<template lang="pug">
    div
        b-form.mt-2(inline)
            b-form-group(label='Chart Metric' inline): b-form-select(v-model='metric')
                option(value='balance') Balances
                option(value='monthlyInterest') Monthly Interest
                option(value='totalInterest') Total Interest Paid
                option(value='payment') Payments

        bar#debt-chart(:data='chartData' :options='chartOptions')
</template>

<script>
import _ from 'lodash'
import { DateTime } from 'luxon'
import { mapGetters, mapState } from 'vuex'
import { Bar } from 'vue-chartjs'
import {
    Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'DebtChart',

    props: ['plan'],

    components: { Bar },

    data() {
        return {
            metric: 'balance',
        }
    },

    computed: {
        chartData() {
            const savingsDataset = []
            if (this.savingsTarget && ['balance', 'payment'].includes(this.metric)) {
                savingsDataset.push({
                    label: 'Savings',
                    backgroundColor: '#09BC8A',
                    data: _.map(this.plan.months, (m) => m[this.metric === 'balance' ? 'totalSavings' : 'savings']),
                })
            }

            return {
                labels: _.map(this.plan.months, (m) => DateTime.fromISO(m.date).toFormat('M/yy')),
                datasets: [
                    ..._.map(this.debts, (debt) => ({
                        label: debt.name,
                        backgroundColor: `#${this.debtColors[debt.id]}`,
                        data: _.map(this.plan.months, (m) => m.debts[debt.id][this.metric]),
                    })),

                    ...savingsDataset,
                ],
            }
        },

        chartOptions() {
            return {
                responsive: true,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                },
            }
        },

        ...mapGetters(['debts', 'debtColors']),
        ...mapState({
            savingsTarget: (state) => state.core.savingsTarget,
        }),
    },
}
</script>
