<template lang="pug">
    div
        router-view
        vuedal
</template>

<script>
import { Vuedal } from '@/util/modals'

export default {
    name: 'App',

    components: { Vuedal },
}
</script>
