<template lang="pug">
    div
        modal-header {{ values.id | addOrEdit }} Income

        .modal-body
            b-form(@submit.prevent='submit')
                b-row
                    b-col
                        b-form-group(label='Name' description='What is the source of this income?'): b-form-input(
                            v-model='model.name'
                            required
                            autofocus
                        )
                    b-col
                        b-form-group(label='Amount' description="What's your monthly income from this?"): b-input-group(prepend='$'): b-form-input(
                            v-model.number='model.amount'
                            type='number'
                            required
                        )

                b-row
                    b-col
                        b-form-group(label='Start Date' description='Optional'): b-form-datepicker(
                            v-model='model.start_date'
                            reset-button
                        )
                    b-col
                        b-form-group(label='End Date' description='Optional'): b-form-datepicker(
                            v-model='model.end_date'
                            reset-button
                        )

                .modal-footer.d-block.px-0
                    b-button.float-left(v-if='values.id' type='button' variant='danger' @click='destroy') Delete
                    b-button.float-right(type='submit' variant='primary') Save
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'IncomeForm',

    props: ['values'],

    data() {
        return {
            model: {
                type: 'income',
                name: '',
                amount: 0,
                start_date: null,
                end_date: null,
                ...this.values,
            },
        }
    },

    methods: {
        async submit() {
            await this.saveMoney({ instance: this.model })
            this.$vuedals.close(this)
        },

        async destroy() {
            await this.destroyMoney(this.model)
            this.$vuedals.close(this)
        },

        ...mapActions(['saveMoney', 'destroyMoney']),
    },
}
</script>
