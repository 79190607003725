import Vue from 'vue'
import _ from 'lodash'
import { DateTime } from 'luxon'

/**
 * Makes lodash functions available as filters.
 */
Vue.filter('startCase', _.startCase)
Vue.filter('titleCase', (s) => _.startCase(_.lowerCase(s)))
Vue.filter('truncate', _.truncate)
Vue.filter('capitalize', _.capitalize)
Vue.filter('lowerCase', _.lowerCase)
Vue.filter('upperCase', _.upperCase)
Vue.filter('toLower', _.toLower)
Vue.filter('toUpper', _.toUpper)
Vue.filter('padStart', _.padStart)
Vue.filter('log', (v) => console.log(v))

/**
 * Create a string with the passed number and the singular or plural word,
 * as appropriate.
 * @param  {Number} num      the number to display and base singular/plural on
 * @param  {String} singular the word to display when num is 1
 * @param  {String} plural   the world to display when num != 1
 * @return {String}           i.e. "1 function" or "2 functions"
 */
export const pluralize = (num, singular, plural) => {
    if (!plural) plural = `${singular}s`
    return `${num.toLocaleString()} ${num === 1 ? singular : plural}`
}
Vue.filter('pluralize', pluralize)

/**
 * Convert a date string into the specified format.
 * @param  {String} dt                original date string
 * @param  {String} [format='DATE_SHORT'] a luxon-compatible format
 * @return {String}                   formatted date string
 */
export const date = (dt, format = 'DATE_SHORT') => {
    const m = DateTime.fromISO(dt, { zone: 'America/New_York' })
    return m.toLocaleString(typeof format === 'string' ? DateTime[format] : format)
}
export const datetime = (dt, format = 'DATETIME_SHORT') => date(dt, format)
export const datetimetz = (dt) => date(dt, {
    month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short',
})
Vue.filter('date', date)
Vue.filter('datetime', datetime)
Vue.filter('datetimetz', datetimetz)

/**
 * Displays a date in the appropriate method for the given grain.
 */
export const dateGrain = (dt, grain) => {
    const grainFormats = {
        day: 'LL/dd/yy',
        week: "'Week of' LL/dd/yy",
        month: 'LLL yyyy',
        quarter: "'Q'q yyyy",
        year: 'yyyy',
    }
    return DateTime.fromISO(dt).toFormat(grainFormats[grain])
}
Vue.filter('dateGrain', dateGrain)

/**
 * Calculate the difference in time from the passed date to now.
 * @param  {String}  dt    date string
 * @param  {Boolean} round whether to round the result
 * @return {String}        difference in time
 */
export const timeFromNow = (dt, round = true) => DateTime.fromISO(dt).toRelative({ round })
Vue.filter('timeFromNow', timeFromNow)

/**
 * Sorts the items by a key (defaulting to name).
 * @param  {Array}  items items to sort
 * @param  {String} key   key to sort by
 * @return {Array}        sorted items
 */
export const sortItems = (items, key = 'name') => _.sortBy(items, key)
Vue.filter('sort', sortItems)

/**
 * Returns "Add" or "Edit" based on the id.
 *
 * @param  {Integer} int the id
 * @return {String}
 */
Vue.filter('addOrEdit', (id) => (id ? 'Edit' : 'Add'))

/**
 * Displays a number in stat format.
 */
const statSuffixes = ['K', 'M', 'B', 'T', 'Q']
export const stat = (number, currency) => {
    let suffixIdx = -1
    while (number >= 1000) {
        number /= 1000
        suffixIdx += 1
    }
    const doubleDigits = currency && suffixIdx === -1
    const roundingAmount = doubleDigits ? 100 : 10
    number = Math.round(number * roundingAmount) / roundingAmount
    if (doubleDigits) number = number.toFixed(2)
    return `${number}${suffixIdx > -1 ? statSuffixes[suffixIdx] : ''}`
}
Vue.filter('stat', stat)

/**
 * Displays a percentage.
 */
Vue.filter('percentage', (number) => `${Math.round(number * 100)}%`)

/**
 * Displays an amount of P&L rounded to two decimals.
 */
export const profitPercentage = (number, options = {}) => `${parseFloat(number).toLocaleString('en-US', { ...{ minimumFractionDigits: 2, maximumFractionDigits: 2 }, ...options })}%`
Vue.filter('profitPercentage', profitPercentage)

/**
 * Displays a number rounded to the specified decimals.
 */
export const round = (number, decimals = 2) => parseFloat(number).toLocaleString('en-US', { ...{ minimumFractionDigits: decimals, maximumFractionDigits: decimals } })
Vue.filter('round', round)

/**
 * Displays an amount of money in currency format.
 */
export const money = (number, options = {}) => `$${parseFloat(number).toLocaleString('en-US', { ...{ minimumFractionDigits: 2, maximumFractionDigits: 2 }, ...options })}`
Vue.filter('money', money)

export const moneyInt = (number, options = {}) => `$${parseFloat(number).toLocaleString('en-US', { ...{ minimumFractionDigits: 0, maximumFractionDigits: 0 }, ...options })}`
Vue.filter('moneyInt', moneyInt)

/**
 * Summarizes a list.
 */
const summarizeList = (list, emptyMessage = '', maxShow = 3) => {
    // If there's nothing in the list, we're done
    if (list.length === 0) return emptyMessage

    // If there's only one item, we're done
    if (list.length === 1) return list[0]

    let commaJoined = []
    let andJoined = ''
    if (list.length > maxShow) {
        commaJoined = list.slice(0, maxShow - 1)
        andJoined = pluralize(list.length - maxShow + 1, 'other')
    } else {
        commaJoined = list.slice(0, list.length - 1)
        andJoined = list[list.length - 1]
    }

    return `${commaJoined.join(', ')} and ${andJoined}`
}
Vue.filter('summarizeList', summarizeList)
