import _ from 'lodash'
import { DateTime } from 'luxon'
import palette from 'google-palette'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

export const totalDebtRemaining = (debts) => _.sumBy(debts, 'balance')

export const moniesForDate = (monies, date) => _.filter(monies, (m) => {
    if (m.start_date && DateTime.fromISO(m.start_date) > date.endOf('month')) return false
    if (m.end_date && DateTime.fromISO(m.end_date) < date) return false
    return true
})

export const roundMoney = (amount) => Math.round((amount + Number.EPSILON) * 100) / 100

export const monthlyInterest = (debt) => roundMoney(((parseFloat(debt.interest) / 100) / 12) * debt.balance)

export const coreStore = {
    state: {
        monies: {},
        planDate: DateTime.now().toISODate(),
        savingsTarget: 0,
        nextId: 1,
    },

    getters: {
        sortedMonies: (state) => _.sortBy(state.monies, 'name'),
        incomes: (state, getters) => _.filter(getters.sortedMonies, { type: 'income' }),
        expenses: (state, getters) => _.filter(getters.sortedMonies, { type: 'expense' }),
        debts: (state, getters) => _.filter(getters.sortedMonies, { type: 'debt' }),
        debtColors: (state, getters) => {
            const colorList = palette(['qualitative'], getters.debts.length)
            const keyedColors = {}
            _.each(_.sortBy(getters.debts, 'id'), (debt, idx) => {
                keyedColors[debt.id] = colorList[idx]
            })
            return keyedColors
        },

        plan: (state, getters) => {
            const months = []
            let date = DateTime.fromISO(state.planDate).startOf('month')
            const debts = _.cloneDeep(getters.debts)
            _.each(debts, (debt) => { debt.totalInterest = 0 })
            let savings = 0
            let iterations = 0

            // Process each month until we're fully paid off
            while ((totalDebtRemaining(debts) > 0 || savings < state.savingsTarget) && iterations < 1000) {
                // Prepare the month
                const month = {
                    date: date.toISODate(),
                    debts: {},
                    purse: 0,
                    savings: 0,
                    totalSavings: 0,
                }
                iterations += 1
                _.each(debts, (debt) => {
                    debt.payment = 0
                    debt.monthlyInterest = 0
                })

                // Determine how much net money we have to work with
                const incomes = moniesForDate(getters.incomes, date)
                const expenses = moniesForDate(getters.expenses, date)
                let purse = _.sumBy(incomes, 'amount') - _.sumBy(expenses, 'amount')
                month.purse = purse
                if (purse <= 0) {
                    console.log(`No money in purse for ${month.date}!`)
                    continue
                }

                // Prepare a payment function to update the purse and pay the debt
                const pay = (debt, amount) => {
                    const trueAmount = Math.min(debt.balance, purse, amount)
                    purse -= trueAmount
                    debt.balance -= trueAmount
                    debt.payment += trueAmount
                    return trueAmount
                }

                // Prioritize the debts based on interest
                const prioritizedDebts = _.sortBy(debts, (debt) => -parseFloat(debt.interest))

                // Apply minimum payments to each debt
                _.each(prioritizedDebts, (debt) => pay(debt, debt.minimum))

                // Pay remaining amounts in purse to debts in prioritized order
                // TODO: We'll want to make this smarter so it can handle pay by dates
                _.each(prioritizedDebts, (debt) => {
                    pay(debt, purse)
                })

                // Calculate interest on remaining balances
                _.each(prioritizedDebts, (debt) => {
                    debt.monthlyInterest = monthlyInterest(debt)
                    debt.totalInterest = roundMoney(debt.totalInterest + debt.monthlyInterest)
                    debt.balance = roundMoney(debt.balance + debt.monthlyInterest)
                })

                // Save any remaining money
                if (purse > 0) {
                    savings += purse
                    month.savings = purse
                    month.totalSavings = savings
                }

                // We're done with this month
                month.debts = _.keyBy(_.cloneDeep(debts), 'id')
                months.push(month)
                date = date.endOf('month').plus({ days: 1 })
            }

            return { months }
        },
    },

    mutations: {
        updateMoney(state, money) {
            if (!money.id) {
                money.id = state.nextId
                state.nextId += 1
            }
            Vue.set(state.monies, money.id, money)
        },

        deleteMoney(state, id) {
            Vue.delete(state.monies, id)
        },

        updatePlanDate(state, date) {
            state.planDate = date
        },

        updateSavingsTarget(state, target) {
            state.savingsTarget = target
        },

        setFullState(state, data) {
            _.each(data, (val, key) => {
                state[key] = val
            })
        },
    },

    actions: {
        async saveMoney({ commit }, { instance }) {
            commit('updateMoney', instance)
        },

        async destroyMoney({ commit }, instance) {
            commit('deleteMoney', instance.id)
        },

        async exportData({ state }) {
            await navigator.permissions.query({ name: 'clipboard-write' })
            navigator.clipboard.writeText(JSON.stringify(state))
        },

        async importData({ commit }) {
            await navigator.permissions.query({ name: 'clipboard-read' })
            const data = JSON.parse(await navigator.clipboard.readText())
            commit('setFullState', data)
        },
    },
}

// Set up persistent objects
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => state,
    filter: () => true,
})

// Set up the root store
const store = new Vuex.Store({
    modules: {
        core: coreStore,
    },
    plugins: [vuexLocal.plugin],
})

export default store
