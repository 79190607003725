import '@/scss/styles.scss'

import Vue from 'vue'

import '@/plugins/bootstrap-vue'
import '@/plugins/vue-js-toggle-button'
import '@/util/drf'
import '@/util/filters'
import '@/util/radio'
import '@/components/registry'
import App from '@/components/App'
import { router } from '@/router'
import store from '@/store'

// Configure Vue
Vue.config.productionTip = false

window.$root = new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
