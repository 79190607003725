<template lang="pug">
    div
        modal-header {{ values.id | addOrEdit }} Debt

        .modal-body
            b-form(@submit.prevent='submit')
                b-row
                    b-col
                        b-form-group(label='Name' description='What is the source of this debt?'): b-form-input(
                            v-model='model.name'
                            required
                            autofocus
                        )
                    b-col
                        b-form-group(label='Balance' description="What's your total balance?"): b-input-group(prepend='$'): b-form-input(
                            v-model.number='model.balance'
                            type='number'
                            required
                        )

                b-row
                    b-col(md='3')
                        b-form-group(label='Interest'): b-input-group(append='%'): b-form-input(
                            v-model='model.interest'
                            type='text'
                            required
                        )
                    b-col(md='3')
                        b-form-group(label='Minimum Payment'): b-input-group(prepend='$'): b-form-input(
                            v-model.number='model.minimum'
                            type='number'
                            required
                        )
                    b-col(md='6')
                        b-form-group(label='Pay Off Date' description="Optionally, when must this debt be paid by?"): b-form-datepicker(
                            v-model='model.pay_by_date'
                            reset-button
                        )

                .modal-footer.d-block.px-0
                    b-button.float-left(v-if='values.id' type='button' variant='danger' @click='destroy') Delete
                    b-button.float-right(type='submit' variant='primary') Save
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'DebtForm',

    props: ['values'],

    data() {
        return {
            model: {
                type: 'debt',
                name: '',
                balance: 0,
                interest: '0.00',
                minimum: 0,
                pay_by_date: null,
                ...this.values,
            },
        }
    },

    methods: {
        async submit() {
            await this.saveMoney({ instance: this.model })
            this.$vuedals.close(this)
        },

        async destroy() {
            await this.destroyMoney(this.model)
            this.$vuedals.close(this)
        },

        ...mapActions(['saveMoney', 'destroyMoney']),
    },
}
</script>
