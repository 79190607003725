import Vue from 'vue'
import Vuedals, { Component as VuedalsComponent, Bus } from 'vuedals'

Vue.use(Vuedals)

export const Vuedal = VuedalsComponent

export const present = (component, props, className, options = {}) => {
    let { closeOnBackdrop } = component
    if (closeOnBackdrop === undefined) {
        // Auto detect whether this modal can be closed on backdrop based on whether form is in its name
        closeOnBackdrop = !(component.name && component.name.toLowerCase().includes('form'))
    }

    Bus.$emit('new', {
        name: className || 'modal-content',
        component,
        props,
        dismissable: false,
        closeOnBackdrop,
        ...options,
    })
}

Vue.prototype.$present = present
