<template lang="pug">
    b-card.mt-2.money(
        body-class='p-0'
        :header='money.name'
        :header-bg-variant='typeVariants[money.type]'
        :header-text-variant='typeTextVariants[money.type]'
    )
        .item-summary
            .item(v-for='item in summary' :key='item.name')
                .item__header {{ item.name }}
                .item__value {{ item.value }}
</template>

<script>
import { moneyInt } from '@/util/filters'

export default {
    name: 'Money',

    props: ['money'],

    data() {
        return {
            typeVariants: {
                debt: 'dark',
                expense: 'light',
                income: 'success',
            },

            typeTextVariants: {
                debt: 'white',
                expense: null,
                income: 'white',
            },
        }
    },

    computed: {
        isDebt() {
            return this.money.type === 'debt'
        },

        summary() {
            let items = [{ name: this.isDebt ? 'Balance' : 'Amount', value: moneyInt(this.isDebt ? this.money.balance : this.money.amount) }]
            if (this.isDebt) {
                items = [
                    ...items,
                    { name: 'Rate', value: `${this.money.interest}%` },
                    { name: 'Minimum', value: moneyInt(this.money.minimum) },
                ]
            }
            return items
        },
    },
}
</script>

<style lang="scss" scoped>
.money {
    cursor: pointer;
}

.item-summary {
    display: flex;
}

.item {
    flex: 1 0 1px;
    text-align: center;
    color: #444;
    font-size: 0.9rem;

    &:not(:last-child) {
        border-right: 1px solid #d9d9d9;
    }

    &__header {
        font-weight: bold;
    }
}
</style>
