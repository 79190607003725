import Vue from 'vue'
import VueRouter from 'vue-router'

import MainPage from '@/pages/MainPage'

Vue.use(VueRouter)

export const router = new VueRouter({
    mode: 'history',

    base: '/',

    scrollBehavior() {
        return { x: 0, y: 0 }
    },

    routes: [
        {
            path: '/',
            component: MainPage,
        },

        { path: '*', redirect: '/' },
    ],
})

export default router
